import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export type MessageType = 'success' | 'error';
export interface StatusType {
  msg: string;
  title: string;
  type: MessageType;
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  timer: number | undefined;
  private readonly status: BehaviorSubject<StatusType | null> =
    new BehaviorSubject<StatusType | null>(null);
  toast$ = this.status.asObservable();

  onShow(
    title: string,
    msg: string,
    autoClose?: boolean,
    type: MessageType = 'success'
  ) {
    const newStatus = { msg, title, type };
    this.status.next(newStatus);

    if (this.timer) {
      clearTimeout(this.timer);
    }

    if (autoClose) {
      this.timer = window.setTimeout(() => {
        this.status.next(null);
      }, 10000);
    }
  }
}
